<template>
    <div>
        <div v-if="preLoad">
            <div class="default_box" v-if="item" itemscope itemtype="http://schema.org/NewsArticle">
                <div class="default_header" v-if="item">
                    <h1 v-if="item.title" v-html="item.title"></h1>
                    <meta itemprop="headline name" :content="item.title">
                    <meta itemprop="description" :content="item.text ? $cutText(item.text, 100) : item.title">
                </div>
                <AdsPlaginNew v-if="$myCity && !$myCity.celat_active" :direction="'horizontal'" />
                <div itemprop="publisher" itemscope itemtype="https://schema.org/Organization">
                    <div itemprop="logo" itemscope itemtype="https://schema.org/ImageObject">
                        <img itemprop="url image" src="/images/logoHD.png" alt="Logo" title="Logo" style="display:none;" />
                    </div>
                    <meta itemprop="name" :content="'Окей Город - '+ $myCity.name">
                    <meta itemprop="telephone" content="">
                    <meta itemprop="address" content="Россия">
                </div>
                <div class="default_box_content bg_white p15" v-if="item">
                    <imageLoader v-if="item.images" :list="item.images"></imageLoader>
                    <div v-if="item.text_html" v-html="item.text_html" itemprop="articleBody" class="wysiwyg"></div>
                    <p class="default_item_text" v-else-if="item.text" v-html="$htmContext(item.text)" itemprop="articleBody"></p>
                    <p class="default_item_source" v-if="item.source_url && item.source_url.includes('http://') || item.source_url && item.source_url.includes('https://')" itemprop="author"><b>Источник: </b> <a :href="item.source_url" class="link_carryover" target="_blank" rel="nofollow">{{item.source_url}}</a></p>
                    <p v-else-if="item.source_url" class="default_item_source" itemprop="author"><b>Источник: </b> {{item.source_url}}</p>
                    <time itemprop="datePublished" :datetime="$moment(item.created_at).local().format('YYYY-MM-DD')"></time>
                    <div class="default_item_footer">
                        <ShareButtons :title="item.title" :link="'https://okaygorod.com' + this.$route.fullPath" :image="item.images && item.images.length ? item.images[0].image : null" />
                        <span v-html="$moment(item.created_at).local().format('DD.MM.YYYY в HH:mm')" class="default_item_footer_date_news"></span>
                    </div>
                </div>
                <VkFrame v-if="item && item.vk_video && item.vk_video.url" :item="item.vk_video"></VkFrame>
                <YouTube v-if="item && item.youtube" :item="item.youtube"></YouTube>
                <Comments :url="this.$config.api_url + this.$route.params.city + '/News.get/' + this.$route.params.id" :comments="comments" :users="users"></Comments>
                <div class="pagination">
                    <router-link v-if="prev" class="col but but-prev" :to="{params: { id:prev}}">Назад
                    </router-link>
                    <router-link v-if="next" class="col but but-next" :to="{params: { id:next }}">Следующая
                    </router-link>
                </div>
                <AdsPlaginNew :direction="'horizontal'" />
            </div>
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
/* eslint-disable no-console */
import imageLoader from "@/components/ImageLoader/imageLoader";
import Comments from "@/components/Comments/index";
import YouTube from '@/components/YouTube/index';
import VkFrame from '@/components/Plagins/VkFrame/index';
import PreLoadBox from "../../Preloader/coin";
import ShareButtons from '@/components/Plagins/ShareButtons';

export default {
    name: "News",
    components: {
        Comments,
        YouTube,
        VkFrame,
        PreLoadBox,
        imageLoader,
        ShareButtons
    },
    metaInfo() {
        return {
            title: this.item && this.item.title ? `${this.item.title} - Новости - ` : null,
            meta: [
                {name: 'description', itemprop: 'description', content: this.item && this.item.text ? `${this.item.text}` : '' },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: this.item && this.item.title ? `${this.item.title} - Новости - ${this.$myCity.name}` : `Новости - ${this.$myCity.name}` },
                { property: 'og:description', content: this.item && this.item.text ? `${this.item.text}` : '' },
                { property: 'og:image', content: this.item && this.item.images && this.item.images[0] && this.item.images[0].image ? this.item.images[0].image : 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'article' }
            ]
        }
    },
    data() {
        return {
            location: location.href,
            preLoad: null,
            item: {},
            next: null,
            prev: null,
            comments: [],
            users: null,
            image: {},
        }
    },

    methods: {
        api() {
            this.preLoad = null;
            this.$scrollToTop();
            this.$http.get(this.url).then((response) => {
                this.users = Object.assign({}, response.data.response.users); 
                this.item = response.data.response.item;
                this.image = response.data.response.item.images.length ? response.data.response.item.images[0].image : '';
                this.next = response.data.response.next;
                this.prev = response.data.response.prev;
                this.comments = response.data.response.comments;
                this.text = this.$htmContext(response.data.response.item.text);
            }).finally(() => {
                this.preLoad = 'finish';

            })
        },
    },
    mounted() {
        this.api();
    },
    watch: {
        '$route.params.id': function() {
            this.api();
        }
    },

    computed: {
        url: {
            get: function() {
                let page = this.$route.name === 'News' ? 'News.get' : 'Interesting.articleGet';
                return this.$config.api_url + this.$route.params.city + `/${page}/` + this.$route.params.id;
            }
        },
    }
}
</script>
<style scoped>
    @import "../../../assets/wysiwyg.css";

    .wysiwyg {
        text-align: initial;
    }
</style>